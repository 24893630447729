.loader-container {
  --uib-size: 24px;
  --uib-color: #533F73;
  --uib-speed: 1.3s;
  --uib-dot-size: calc(var(--uib-size) * 0.24);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  width: 56px;
  gap:4px;
}

.dot,
.loader-container::before,
.loader-container::after {
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 16px 16px 16px 0;
  background-color: var(--uib-color);
  transform: scale(1);
  transition: background-color 0.3s ease;
}
.loader-container::before {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375) infinite;
}

.dot {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25) infinite both;
}

.loader-container::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125) infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
