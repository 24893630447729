.custom_search__control {
  text-align: left;
}
.custom_search__control--is-focused {
  border-color: #b6a2d8 !important;
  box-shadow: 0 0 0 1px #b6a2d8 !important;
  border-bottom-color: #d7d7d7 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom_search__placeholder {
  margin: 0 !important;
}

.custom_search__input {
  box-shadow: none !important;
  margin: 4px 64px 4px 0px !important;
}

.custom_search__input-container {
  margin-left: 0 !important;
  margin-right: 32px !important;
}

.custom_search__value-container {
  margin-left: 8px;
}

.custom_search__menu {
  margin: 0 !important;
  /* padding: 20px 24px; */
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px !important;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-sizing: border-box;
  border: #b6a2d8 1px solid !important;
  border-top: #d7d7d7 !important;
  box-shadow: 0 0 0 1px #b6a2d8 !important;
}
.custom_search__menu ::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.custom_search__menu ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #00004d;
  -webkit-box-shadow: inset 0 0 6px #00004d;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

/* Handle */
.custom_search__menu ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  background: #636567;
  box-shadow: inset 0 0 6px #636567;
  -webkit-box-shadow: inset 0 0 6px #636567;
}
.custom_search__menu ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(99, 101, 103, 0.4);
}

.custom_search__menu * {
  text-align: left !important;
}

.custom_search__multi-value {
  border-radius: 4px !important;
  padding: 2px 6px;
  background-color: #fafafa !important;
  border: 1px solid #d0d2d9;
  margin: 4px !important;
}

.custom_search__multi-value__remove:hover {
  background-color: transparent !important;
}

.custom_search__multi-value__remove svg {
  width: 24px;
  height: 24px;
  color: #5c1fc4;
}

.custom_search__multi-value__remove svg:hover {
  color: red;
}

.custom_search__multi-value__label {
  font-size: 16px !important;
  padding-left: 8px !important;
  margin-right: 8px;
}
