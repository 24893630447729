button {
  font-size: 16px;
}

.primary-button {
  @apply bg-primary-900 text-white  focus:bg-primary-800 focus:text-white disabled:cursor-not-allowed;
  /* hover:bg-primary-50 hover:text-primary-900 */
}

.secondary-button {
  @apply bg-white border text-primary-900 border-primary-900 hover:border-primary-600 hover:text-primary-600 focus:border-primary-900 focus:text-primary-900 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed;
}

.gray-button {
  @apply bg-white border text-secondary-800 border-appGray-600 hover:border-appGray-400 hover:text-appGray-600 focus:border-appGray-600 focus:text-appGray-600 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed;
}

.auth-button {
  @apply bg-primary-500 text-white  focus:bg-primary-800 focus:text-white disabled:cursor-not-allowed disabled:bg-primary-500/50;
}

.danger-button {
  @apply bg-danger-500 border text-white border-danger-500 hover:border-danger-500 hover:text-white focus:border-danger-500 focus:text-white disabled:border-danger-500 disabled:text-white disabled:cursor-not-allowed;
} 

.optional-button {
  @apply bg-secondary-500 text-white hover:bg-secondary-400 hover:text-white disabled:hover:bg-secondary-500 disabled:text-gray-700 disabled:cursor-not-allowed border border-secondary-500;
}

.full-width {
  @apply min-w-[174px];
}

.full-width .flex {
  @apply w-full justify-center;
}
