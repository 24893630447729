.wrapper {
  box-sizing: content-box;
  display: flex;
  height: 40px;
}

.words {
  overflow: hidden;
}

.words span {
  display: block;
  height: 100%;
  animation: slide 10s infinite;
}

/* Adjust animation duration based on the number of texts and speed */
@keyframes slide {
  0% {
    transform: translateY(-20%);
  }
  12.5% {
    transform: translateY(-100%);
  }
  25% {
    transform: translateY(-200%);
  }
  37.5% {
    transform: translateY(-300%);
  }
  50% {
    transform: translateY(-400%);
  }
  62.5% {
    transform: translateY(-500%);
  }
  75% {
    transform: translateY(-600%);
  }
  87.5% {
    transform: translateY(-700%);
  }
  100% {
    transform: translateY(-800%);
  }
}


