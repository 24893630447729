table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  border: 1px solid #d7d7d7;
  border-radius: 6px !important;
}

th,
td {
  padding: 4px;
}

th {
  border-bottom: 1px solid #d7d7d7;
  background-color: #f5f7ff;
}

td {
  border-bottom: 1px solid #d7d7d7;
}

.stream-answer span {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #442873;
  display: inline-block;
  animation: blink 3s infinite; /* Apply the blink animation */
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}
