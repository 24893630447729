.country-name {
  @apply bg-white text-gray-700 border border-gray-200 opacity-100 p-2 shadow-lg rounded-lg;
}

.country-name::after {
  @apply border-t-white border-b-white;
}

.map-tooltip {
  @apply bg-white text-gray-700 border border-gray-200 opacity-100 p-2 shadow-lg rounded-2xl z-50;
}

.map-tooltip::after {
  @apply border-t-white border-b-white;
}

.tooltip {
  @apply z-30 max-w-md;
}
